:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(204, 87%, 94%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  --max-width: 1170px;
  --fixed-width: 450px;
  --clr-orange-1: hsl(12, 83%, 98%);
  --clr-orange-2: hsl(14, 91%, 95%);
  --clr-orange-3: hsl(12, 89%, 89%);
  --clr-orange-4: hsl(13, 87%, 82%);
  --clr-orange-5: hsl(13, 88%, 68%);
  --clr-orange-6: hsl(13, 88%, 55%);
  --clr-orange-7: hsl(13, 74%, 49%);
  --clr-orange-8: hsl(13, 74%, 33%);
  --clr-orange-9: hsl(13, 73%, 25%);
  --clr-orange-10: hsl(13, 73%, 16%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background: var(--clr-grey-10) !important;
  color: var(--clr-grey-1) !important;
  line-height: 1.5 !important;
  font-size: 1rem !important;
}
ul {
  list-style-type: none !important;
}
a {
  text-decoration: none !important;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing) !important;
  text-transform: capitalize !important;
  line-height: 1.25 !important;
  margin-bottom: 0.75rem !important;
}
h1 {
  font-size: 3rem !important;
}
h2 {
  font-size: 2rem !important;
}
h3 {
  font-size: 1.25rem !important;
}
h4 {
  font-size: 0.875rem !important;
}
p {
  margin-bottom: 1.25rem !important;
  color: var(--clr-grey-5) !important;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4.5rem !important;
  }
  h2 {
    font-size: 2.8rem !important;
  }
  h3 {
    font-size: 1.8rem !important;
  }
  h4 {
    font-size: 1.3rem !important;
  }
  h5 {
    font-size: 1.2rem !important;
  }
  body {
    font-size: 1.1rem !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1 !important;
  }
}

/* CSS for Components*/
.nav-link{
  font-weight: 500 !important;
  color: rgb(114, 95, 182) !important;
}
.nav-link:hover{
  color:rgb(218, 131, 32) !important;
}
.dropdown-item{
  font-weight: 500 !important;
  color: rgb(114, 95, 182) !important;
}
.dropdown-item:hover{
  color:rgb(218, 131, 32) !important;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: #d8eedb !important;
}
.mainimg{
  /* padding: 10px;
  border-radius: 13px; */
  clip-path: polygon(100% 0, 100% 95%, 0% 100%, 0 0%);
}
.link1{
  color:rgb(70, 62, 100) !important;
}
.link1:hover{
  color:rgb(226, 160, 84) !important;
}
/* .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
 } */
.btn1{
  background-color: rgb(2, 116, 2) !important;
  color: whitesmoke !important;
  font-weight: 500 !important;
}
footer{
  background-color: var(--clr-orange-2) !important;
  font-weight: 500 !important;
}
.album {
  background-color: var(--clr-orange-1) !important;
}
.card{
  background-color: var(--clr-grey-10) !important;
}
.card-body h5{
  color: var(--clr-orange-7) !important;
}
/* .about{
  background-color: #222;
} */